import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class StakeService {
  serviceData: boolean =true;

  private currentValue = new BehaviorSubject(this.serviceData);
  value = this.currentValue.asObservable();
  
  public getData():boolean {
    return this.serviceData;
}
public setData(value: boolean) {
    this.serviceData = value;
}

changeValue(value: boolean) {
  this.currentValue.next(value)
}
}